<template>
  <div>
    <div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">考试查询</div>
        </div>
        <el-table :data="tableData" border row-key="id"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column prop="user.id" label="编号" />
          <el-table-column label="角色" >
            <template #default="{row}">
              <div>
                {{ row.type == 0 ? '学生' : '教师' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="user.code" label="学号 / 教职工号" />
          <el-table-column prop="user.name" label="姓名" />
          <el-table-column prop="user.username" label="用户名" />
          <el-table-column label="院系 / 单位">
            <template #default="{row}">
              <div>
                {{ row.type == 0 ? row.user.school : row.user.department  }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="专业">
            <template #default="{row}">
              <div>
                {{ row.type == 0 ? row.user.major : '' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="班级">
            <template #default="{row}">
              <div>
                {{ row.type == 0 ? row.user.clasz : '' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="score" label="分数" />
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getCheckInfo, getPaperAll} from "@/api/examination";
import { floatParse } from "@/utils/common";
export default {
  name: "examUserList",
  data(){
    return{
      tableData:[],
      id: null,
      pageInfo:{
        limit:10,
        page:1,
        total:0,
      },
      // 考试选项
      examPaperOptions: [],
      //
      getOverPage: getCheckInfo
    }
  },
  mounted() {
    this.getOption()
    this.id = this.$route.params.id
    if (this.id) {
      this.getOverPage = getCheckInfo(this.id)
      this.getList()
    } else {
      this.$root.useRouter.replace('/examResult/list')
    }
  },
  methods:{
    floatParse,
    /**
     * 获取考试
     */
    getOption(){
      getPaperAll().then(res => {
        res.data.forEach(iter => {
          this.examPaperOptions.push({
            label: iter.name,
            value: iter.id
          })
        })
      })
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      this.getOverPage(this.pageInfo).then(res=>{
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;
      })
    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    }
  }
}
</script>

<style lang="scss" scoped>
.data-title{font-size: 15px;color: #000000}
.data-content{
  font-size: 13px;display: flex;justify-content: space-between;margin-top: 15px;
}
</style>